import React, { useEffect, useState } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import SwitchSelector from 'react-switch-selector'
import UntouchedLogo from './images/untouchedlogo.png'
import LanuchLogo from './images/Launch.svg'
import Web from './images/website.svg'
import Deepthi from './images/Deepthi.svg'
import danish from './images/danish.svg'
import location from './images/location.svg'
import Call from './images/call.svg'
import Email from './images/email.svg'
import Insta from './images/insta.svg'
import LinkedIn from './images/linkedin.svg'
import Twitter from './images/twitter.png'
import axios from 'axios'

const App = () => {
  const [investPlanType, setInvestPlanType] = useState(0)
  const [investAmount, setInvestmentAmount] = useState(5000)
  const [minimumAmount, setMinimumAmount] = useState(5000)
  const [maximumAmount, setMaximumAmount] = useState(25000)
  const [profitPercentage, setProfitPercentage] = useState(0.0)
  const [amountInterval, setAmountInterval] = useState(5000);
  const [totalAmount,setTotalAmount] = useState(0);

  const [payment,setPayment] = useState('');
  
  const [oncePaylist,setOncePayList] = useState([
    {
      value:'0',
      amount:5000,
      url:'https://rzp.io/l/TaAnqLPnzJ'
    },
    {
      value:'1',
      amount:10000,
      url:'https://rzp.io/l/BqfUXKe'
    },
    {
      value:'2',
      amount:15000,
      url:'https://rzp.io/l/OoqXnIo'
    },
    {
      value:'3',
      amount:20000,
      url:'https://rzp.io/l/CU8qELxrsS'
    },
    {
      value:'4',
      amount:25000,
      url:'https://rzp.io/l/mghlPkEMX'
    }
  ]);

  const [monthlyPaylist,setMonthlyPayList] = useState([
    {
      value:'0',
      amount:1000,
      url:'https://rzp.io/l/MoOgAobqY'
    },
    {
      value:'1',
      amount:2000,
      url:'https://rzp.io/l/CLSiVOb'
    },
    {
      value:'2',
      amount:3000,
      url:'https://rzp.io/l/tbRo1KBk'
    },
    {
      value:'3',
      amount:4000,
      url:'https://rzp.io/l/y3CufjL'
    },
    {
      value:'4',
      amount:5000,
      url:'https://rzp.io/l/ttUmiyyd'
    },
  ]);

  const [minMaxAmount,setminMaxAmount] = useState([
    {
     value: 5000,
     minAmount:584,
     maxAmount:2336
    },
    {
      value: 10000,
      minAmount:1168,
      maxAmount:4672
     },
     {
      value: 15000,
      minAmount:1752,
      maxAmount:7009
     },
     {
      value: 20000,
      minAmount:2336,
      maxAmount:9345
     },
     {
      value: 25000,
      minAmount:2920,
      maxAmount:11681
     },
  ])

  const [investerData, setInvesterData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobileNo: '',
    refName: '',
  })

  const { firstname, lastname, email, mobileNo, refName } = investerData;

  const onHandleChange = (e: any) => {
    setInvesterData({ ...investerData, [e.target.name]: e.target.value })
  }

  const onHandleChangeMobileNo = (e: any) => {
    let telephone = e.target.value

    if (!Number(telephone)) {
      return
    }
    setInvesterData({ ...investerData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    let percentage: any
    if (investPlanType === 0) {
      percentage = ((58 / 5000000) * investAmount).toFixed(3);
      setTotalAmount(investAmount);
      investAmountChange(investAmount);
    } else {
      percentage = ((58 / 5000000) * (5 * investAmount)).toFixed(3);
      setTotalAmount(5*investAmount);
      investAmountChange(investAmount);
    }
    setProfitPercentage(+percentage);
  }, [investAmount, investPlanType]);

  const investAmountChange = (value: number) => {
    setInvestmentAmount(value);
    let percentage: any
    let e: any;
    let btn = document.getElementById(`${value.toString()}`) || e;
    btn.click();
    btn.focus();
    if (investPlanType === 0) {
      percentage = ((58 / 5000000) * investAmount).toFixed(3);
      setTotalAmount(investAmount);
    } else {
      percentage = ((58 / 5000000) * (5 * investAmount)).toFixed(3);
      setTotalAmount(5*investAmount);
    }
    setProfitPercentage(+percentage);
  }

  const onChangeInvestPlanType = (planType: any) => {
    setInvestPlanType(planType)
    if (investPlanType === 0) {
      //Once
      setAmountInterval(1000);
      setInvestmentAmount(1000);
      setMinimumAmount(1000);
      setMaximumAmount(5000);

    } else {
      // Monthly
      setAmountInterval(5000)
      setInvestmentAmount(5000)
      setMinimumAmount(5000)
      setMaximumAmount(25000);
    }
  }

  const paymentOptions = [
    {
      label: 'Once',
      value: 0,
      selectedBackgroundColor: 'black',
    },
    {
      label: 'Monthly',
      value: 1,
      selectedBackgroundColor: 'black',
    },
  ];

  const selectRazorPaymentUrl = async (e: any) => {
    e.preventDefault();
    window.open(payment, "_self");
  }

  const openReferalPage = async (e: any) => {
    e.preventDefault();
    window.open('https://forms.gle/btyPEYRvRCpYdrY98', "_self");
  }

  const TremsAndConditions = async (e: any) => {
    e.preventDefault();
    window.open('https://www.notion.so/soaron/Terms-Conditions-Untouched-Investment-2ab64fef21bf47da8ab2f3792fa0aa8e',"_self");
  }

  const onPaySelection = (e: any,amount: any,url: any) => {
    e.preventDefault();
    setPayment(url);
    setInvestmentAmount(amount);
  }

  return (
    <div className="fullWidth">

      <div className="flex flex-col sm:flex-row">
      <div className="justify-self-start">
          <img
            src={UntouchedLogo}
            alt="..."
            className="w-20 h-14 align-middle border-none"
          />
      </div>
      <div className="">
      <div className="flex flex-row justify-center items-center backgroundColorRight pt-4">
          <button
            className="text-center w-auto bg-black rounded-3xl text-white py-0 px-0 md:px-4 ml-0 flex flex-row
            font-medium focus:outline-none cursor-pointer"
            onClick={openReferalPage}
          >
            <p className="pr-2 text-xs md:text-sm py-1 -mb-1">Refer in your network & get the profit</p>
            <p className="bg-gray-100 py-1 -mr-4 px-2 rounded-full text-xs md:text-sm text-black">Refer Now</p>
          </button>
      </div>
      <div className="flex flex-col sm:flex-row backgroundColorRight">
        <div className="w-auto sm:w-3/5 flex flex-col backgroundColorRight">
          <div className="px-11 sm:px-28 pt-6 sm:pt-24">
            <h1 className="text-2xl sm:text-3xl pl-1 font-semibold text-white">
              Investment that will make <br /> you feel progressive
            </h1>
            {/* <p className="pl-1 font-medium">
              Investment that will make you progress
            </p> */}
          </div>
          <div className="flex flex-col justify-center items-center pt-2 sm:pt-0">
            <img
              src={LanuchLogo}
              alt="..."
              className="rounded w-48 sm:w-80 h-40 sm:h-64 align-middle border-none x-10"
            />
          </div>
          <div className="px-11 sm:px-28 pt-6">
            <p className="pl-1 text-md sm:text-lg pr-9 sm:pr-4 text-white text-center sm:text-left">
              Investing on the COVID essentials at the right time is the star
              investment rule & as <b>`Project Untouched- is the need of the hour`</b>
              makes your investment more reliable.
            </p>
          </div>
        </div>
        {/* <div className="flex flex-row justify-center items-center"> */}
        <div>
        <div className="h-auto md:bg-white flex flex-col space-t-10 justify-center items-center mt-7 mb-0 opacity-100 rounded">
          <div className="bg-white w-96 shadow border border-gray-200 rounded p-4">
            <h1 className="text-1xl sm:text-1xl font-bold investNowColor">
              Invest Now
            </h1>
            <div className="reactSlider mx-24 my-3 border-sm">
              <SwitchSelector
                onChange={onChangeInvestPlanType}
                options={paymentOptions}
                initialSelectedIndex={investPlanType}
                backgroundColor={'#F2F2F2'}
                fontColor={'black'}
              />
            </div>
            <p className="text-sm pt-2 mb-2">Choose the amount to investment</p>
            <div className="container mx-auto px-4 btnFormBg">
                <h1 className="text-1xl sm:text-1xl py-2 font-bold investNowColor">Investment Amount</h1>
                <div className="flex flex-wrap content-center justify-between">
                  {
                    investPlanType === 0?
                    <>{oncePaylist.map((pay) => (
                      <div><button className={`py-0 px-0 rounded-4 focus:outline-none btnUrl rounded text-sm
                      ${pay.amount === investAmount?"btnFocus":""}`} id={pay.amount.toString()}
                      onClick={(e)=>{onPaySelection(e,pay.amount,pay.url)}}>
                        &#8377;{pay.amount}</button></div>
                      ))}
                    </>
                    :
                    <>
                    {monthlyPaylist.map((pay) => (
                    <div><button className={`py-0 px-0 rounded-4 focus:outline-none btnUrl rounded text-sm
                    ${pay.amount === investAmount?"btnFocus":""}`} id={pay.amount.toString()}
                    onClick={(e)=>{onPaySelection(e,pay.amount,pay.url)}}>
                      &#8377;{pay.amount}</button></div>
                    ))}
                    </>
                  }         
                </div>
                <h1 className="text-1xl sm:text-1xl py-2 font-bold investNowColor">Return on Investment</h1>
                <div className="flex flex-row justify-between items-center bg-white py-2">
                    {
                    minMaxAmount.map((rec) => (
                      <>
                       {
                         rec.value === totalAmount ?
                         <>
                          <div className="value pl-2">
                            <p className="text-sm">Minimum</p>
                            <p className="text-sm font-medium">&#8377;{rec.minAmount}</p>
                          </div>
                          <div className="value pr-2">
                            <p className="text-sm">Maximum</p>
                            <p className="text-sm font-medium">&#8377;{rec.maxAmount}</p>
                          </div>
                         </>
                         :
                         <>
                         </>
                       }
                     </>
                    ))
                    }
                </div>
                <div className="pt-4"></div>
              </div>
            <div className="slider" onClick={()=> {investAmountChange(investAmount)}}>
              {/* here above */}
              <Slider
                min={minimumAmount}
                max={maximumAmount}
                step={amountInterval}
                value={investAmount}
                onChange={investAmountChange}
              />
              <div className="flex flex-row justify-between mb-2">
                <div className="value">
                  <p className="text-sm">Invest Amount</p>
                  <p className="text-sm font-medium">&#8377;{investAmount}</p>
                </div>
                <div className="value">
                  <p className="text-sm">Profit Percentage</p>
                  <p className="text-sm font-medium">{profitPercentage}%</p>
                </div>
              </div>
              <hr className="pt-1" />
              {investPlanType === 1 ? (
                <p className="pt-1 text-sm">
                  You will be investing monthly &#8377;{investAmount} for the
                  coming 5 months and the total investment amount will be
                  &#8377;{investAmount * 5}.The profit for the given amount is{' '}
                  {profitPercentage}%
                </p>
              ) : (
                <p className="pt-1 text-sm">
                  You will be investing &#8377;{investAmount}.The profit for the
                  given amount is {profitPercentage}%
                </p>
              )}
              <hr className="" />
            </div>
            <form
              className="space-y-5 mt-4"
              autoComplete="chrome-off"
              name="googlesheet"
            >
              <p className="text-sm">
                <b>Note: </b>Untouched Investment is not crowdfunding, we are
                taking investment amount from the network of known people.
              </p>
              <div className="flex justify-center">
                <button
                  className="text-center w-auto bg-black rounded-3xl text-white py-2 sm:py-1 px-36 sm:px-12 ml-0
                  font-medium focus:outline-none"
                  onClick={selectRazorPaymentUrl}
                >
                  Proceed
                </button>
              </div>
              <p className="redirectPage">
                You will be redirected to Razorpay payments gateway page
              </p>
            </form>
          </div>
        </div>
        <div><p className="text-sm text-white mb-6 pt-1 cursor-pointer" onClick={TremsAndConditions}>*Terms and Conditions</p></div>
        </div>
      </div>
      <div className="flex flex-col align-middle items-center">
        <div className="pt-12">
          <h1 className="text-1xl sm:text-xl pl-1 font-semibold text-black">
            Our investors
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-4 pt-6 mb-7  justify-items-center">
          <div className="flex flex-col justify-center items-center">
            <div>
              <img
                src={Deepthi}
                alt="..."
                className="w-19 h-16 align-middle border-none rounded-full"
              />
            </div>
            <div className="pt-1">
              <p className="justify-self-start font-medium">Deepthi Ayyagari</p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div>
              <img
                src={danish}
                alt="..."
                className="w-19 h-16 align-middle border-none rounded-full"
              />
            </div>
            <div className="pt-1">
              <p className="justify-self-start font-medium">Danish Khan</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 h-auto sm:h-36 w-full">
          {/* <button className="text-center w-auto bg-black rounded-3xl text-white py-1 
               px-4 ml-4 text-xs sm:text-lg font-medium focus:outline-none">
                 Website: untouched.soaron.co | Email: invest@soaron.co | Phone: +91-00000000000</button> */}
          {/* <div className="pt-2 flex flex-col align-middle items-center">
            <h1 className="text-1xl sm:text-xl pl-1 font-semibold text-black">
              Contact Us
            </h1>
          </div> */}
          <div className="flex flex-col sm:flex-row justify-around items-center">
            <div>
            <div className="pt-2 flex flex-col align-middle items-center">
                <h1 className="text-1xl sm:text-xl pl-1 font-semibold text-black">
                  Contact Us
                </h1>
              </div> 
              <div className="flex flex-row pt-2">
                <div className="flex flex-col pr-4">
                  <div className="contactInfoHeaders flex flex-row">
                  <img src={Web} alt="..." className="w-6 h-5 pt-1 align-middle border-none rounded-full"/>Website</div>
                  <div className="contactInfoHeaders flex flex-row">
                  <img src={Email} alt="..." className="w-6 h-5 pt-1 align-middle border-none rounded-full"/>Email</div>
                  <div className="contactInfoHeaders flex flex-row">
                  <img src={Call} alt="..." className="w-6 h-5 pt-1 align-middle border-none rounded-full"/>Call</div>
                </div>
                <div className="flex flex-col">
                  <div className="contactInfoColor cursor-pointer">
                    <a
                      target="_blank"
                      href="https://untouched.soaron.co/"
                      rel="noopener noreferrer"
                    >
                      untouched.soaron.co
                    </a>
                  </div>
                  <div className="contactInfoColor">invest@soaron.co</div>
                  <div className="contactInfoColor">+91-9059489056</div>
                </div>
              </div>
            </div>
            <div>
            <div className="flex flex-col pt-2 mb-6 sm:mb-2 sm:mb-0">
              <div className="pt-2 flex flex-col align-middle items-center">
                <h1 className="text-1xl sm:text-xl pl-1 font-semibold text-black">
                    Address
                </h1>
               </div> 
                <div className="flex flex-col pr-4">
                  <div className="contactInfoHeaders pl-4 sm:pl-0 flex flx-row">
                  <img src={location} alt="..." className="w-6 -ml-1 h-5 pt-1 align-middle border-none rounded-full"/>Location
                  <a
                      target="_blank"
                      href="https://g.page/soaron"
                      rel="noopener noreferrer"
                      className="contactInfoColor cursor-pointer pl-4"
                    >
                      https://g.page/soaron
                    </a>
                   </div>
                  <div className="contactInfoHeaders text-sm pt-1 pl-4 sm:pl-0">10-1-128/1/2D, Ali Manor Complex,<br />
                  2nd Floor, Masab Tank, Hyderabad,<br /> Telangana 500028</div>
                </div>
                {/* <div className="flex flex-col">
                  <div className="contactInfoColor cursor-pointer">
                    <a
                      target="_blank"
                      href="https://g.page/soaron"
                      rel="noopener noreferrer"
                    >
                      https://g.page/soaron
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>  
      <div className="border-t-0 flex flex-col sm:flex-row justify-around items-center h-16">
        <div className="pl-4 footerh1">© 2021 Untouched by Soaron</div>
        <div className="sm:pr-4 pl-4 sm:pl-0 footerh1 flex flex-row">
          <p className="pt-0">On social networks </p>
          <a
            className="pt-1"
            target="_blank"
            href="https://www.instagram.com/projectuntouched/"
            rel="noopener noreferrer"
          >
            <img
              src={Insta}
              alt="..."
              className="w-5 h-5 ml-1 align-middle border-none"
            />
          </a>
          <a
            className="pt-1"
            target="_blank"
            href="https://twitter.com/ProjectUntouch"
            rel="noopener noreferrer"
          >
            <img
              src={Twitter}
              alt="..."
              className="w-5 h-5 ml-1 align-middle border-none"
            />
          </a>
          <a
            className="pt-1"
            target="_blank"
            href="https://www.linkedin.com/company/projectuntouch/"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedIn}
              alt="..."
              className="w-5 h-5 ml-1 align-middle border-none"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default App
